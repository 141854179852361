import { ReactComponent as CheckboxInactive} from './CheckboxInactive.svg';
import { ReactComponent as CheckboxActive} from './CheckboxActive.svg';
import { ReactComponent as AwaitingTransport} from './AwaitingTransport.svg';
import { ReactComponent as InTransit} from './InTransit.svg';
import { ReactComponent as UndergoingAnalysis} from './UndergoingAnalysis.svg';
import { ReactComponent as FurtherAnalysis} from './FurtherAnalysis.svg';
import { ReactComponent as DeletionRequested} from './DeletionRequested.svg';
import { ReactComponent as Missing} from './Missing.svg';
import { ReactComponent as ResultsAvailable} from './ResultsAvailable.svg';
import { ReactComponent as ResultsReported} from './ResultsReported.svg';
import { ReactComponent as ResultsShared} from './ResultsShared.svg';
import { ReactComponent as Disposed} from './Disposed.svg';
import { ReactComponent as Pickup} from './Pickup.svg';
import { ReactComponent as Dropoff} from './Dropoff.svg';
import { ReactComponent as Route} from './Route.svg';
import { ReactComponent as LoginTopBorder} from './LoginTopBorder.svg';
import { ReactComponent as BarChart} from './BarChart.svg';
import { ReactComponent as Logout} from './Logout.svg';
import { ReactComponent as Logo} from './Logo.svg';
import { ReactComponent as LoginLogo} from './LoginLogo.svg';
import { ReactComponent as CourierLogo} from './CourierLogo.svg';
import { ReactComponent as SidebarLogo} from './SidebarLogo.svg';
import { ReactComponent as Check} from './Check.svg';
import { ReactComponent as Alert} from './Alert.svg';
import { ReactComponent as ArrowRight} from './ArrowRight.svg';
import { ReactComponent as Chevron} from './Chevron.svg';
import { ReactComponent as Edit} from './Edit.svg';
import { ReactComponent as Plus} from './Plus.svg';
import { ReactComponent as Search} from './Search.svg';
import { ReactComponent as FirstPage} from './FirstPage.svg';
import { ReactComponent as PrevPage} from './PrevPage.svg';
import { ReactComponent as NextPage} from './NextPage.svg';
import { ReactComponent as LastPage} from './LastPage.svg';
import { ReactComponent as Close} from './Close.svg';
import { ReactComponent as CloseBtn} from './CloseBtn.svg';
import { ReactComponent as SwitchOff} from './SwitchOff.svg';
import { ReactComponent as SwitchOn} from './SwitchOn.svg';
import { ReactComponent as Chart} from './Chart.svg';
import { ReactComponent as Back} from './Back.svg';
import { ReactComponent as ReviewAlert} from './ReviewAlert.svg';
import { ReactComponent as IsPublic} from './IsPublic.svg';
import { ReactComponent as Location} from './Location.svg';
import { ReactComponent as NotAnalyzed} from './NotAnalyzed.svg';
import { ReactComponent as Phone} from './Phone.svg';
import { ReactComponent as Mail} from './Mail.svg';
import { ReactComponent as Download} from './Download.svg';
import { ReactComponent as DownloadBlack} from './DownloadBlack.svg';
import { ReactComponent as DownloadCircle} from './DownloadCircle.svg';
import { ReactComponent as DefaultSort} from './DefaultSort.svg';
import { ReactComponent as ActiveSort} from './ActiveSort.svg';
import { ReactComponent as BlackCheck} from './BlackCheck.svg';
import { ReactComponent as ExternalLink} from './ExternalLink.svg';
import { ReactComponent as CouldNotShareStatus} from './CouldNotShareStatus.svg';

// eslint-disable-next-line
export default {
    CheckboxInactive,
    CheckboxActive,
    AwaitingTransport,
    InTransit,
    UndergoingAnalysis,
    FurtherAnalysis,
    DeletionRequested,
    Missing,
    ResultsAvailable,
    ResultsReported,
    ResultsShared,
    Disposed,
    Pickup,
    Dropoff,
    Route,
    LoginTopBorder,
    BarChart,
    Logout,
    Logo,
    LoginLogo,
    CourierLogo,
    SidebarLogo,
    Check,
    Alert,
    ArrowRight,
    Chevron,
    Edit,
    Plus,
    Search,
    FirstPage,
    PrevPage,
    NextPage,
    LastPage,
    Close,
    CloseBtn,
    SwitchOff,
    SwitchOn,
    Chart,
    Back,
    ReviewAlert,
    IsPublic,
    Location,
    NotAnalyzed,
    Phone,
    Mail,
    Download,
    DownloadBlack,
    DownloadCircle,
    DefaultSort,
    ActiveSort,
    BlackCheck,
    ExternalLink,
    CouldNotShareStatus,
}