import React, {FC, useCallback, useContext, useEffect, useState} from 'react';

import { Checkbox, Fullname } from '../../Components';
import { getUserFacingSampleType } from '../../helpers';
import { confirmReceiptSamples } from '../../requests';
import { StateContext } from '../../state';

import './style.css';

type IProps = {
    checkedSamples: any[];
    onDone: (checkedSamples: any[], fullname?: string) => void;
    onLoading: (status: boolean) => void;
}

const ConfirmReceiptSamples: FC<IProps> = (props: IProps) => {
    const {dispatch} = useContext(StateContext);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [checkedSamples, setCheckedSamples] = useState<any[]>([]);
    const [courierName, setCourierName] = useState<string>('');
    const [fullname, setFullname] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setCheckedSamples(props.checkedSamples);
    }, [props.checkedSamples])

    useEffect(() => {
        props.onLoading(loading);
    }, [loading, props])

    const submitHandler = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (checkedSamples.length === 0) {
            props.onDone([], fullname);
            return;
        }
        try {
            setLoading(true);
            await confirmReceiptSamples(checkedSamples.map(cs => cs.sample_id), courierName, fullname);
            props.onDone(checkedSamples, fullname);
        } catch (err: any) {
            dispatch({type: "showAlert", payload: {
                error: true,
                message: err.message,
            }});
        } finally {
            setLoading(false);
        }
    }, [checkedSamples, courierName, fullname, dispatch, props])

    const IsDisabled = !courierName || !fullname || !confirm;

    return (
        <form className="receiptsamples" onSubmit={submitHandler}>
            <div className="field">
                <label className="label">
                    Confim that the samples below are present. Uncheck any samples that cannot be accounted for.
                </label>
                <div className="box CAMH">
                    {props.checkedSamples.map((sample: any, index: number) => {
                        return (
                            <div key={index} className="boxrow">
                                <Checkbox
                                    checked={!!checkedSamples.find(cs => cs.sample_id === sample.sample_id)}
                                    onChange={(s: boolean) => {
                                        const item = checkedSamples.find(cs => cs.sample_id === sample.sample_id);
                                        if (item && !s) {
                                            setCheckedSamples(os => os.filter(o => o.sample_id !== item.sample_id));
                                        } else if (!item && s) {
                                            setCheckedSamples(os => [...os, sample]);
                                        }
                                    }} 
                                />
                                <div className="boxsample" style={{marginRight: '4.75rem'}}>
                                    <span className="sampleidlabel">Sample ID:</span>
                                    <span className="sampleid">{sample.sample_id}</span>
                                </div>
                                <div className="boxsample">
                                    <span className="sampleidlabel">Sample type:</span>
                                    <span className="sampleid">{getUserFacingSampleType(sample.sample_type)}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="field">
                <label className="label">
                    Full name of person who transported sample(s): <span className="required">*</span>
                </label>
                <Fullname onChange={(fullname: string) => setCourierName(fullname)} />
            </div>
            <div className="field">
                <label className="label">
                    Full name of staff that is accepting sample(s): <span className="required">*</span>
                </label>
                <Fullname onChange={(fullname: string) => setFullname(fullname)} />
            </div>
            <div className="field">
                <Checkbox
                    required={true}
                    checked={confirm} 
                    onChange={(s: boolean) => setConfirm(s)}
                    label="I confirm that the sample(s) are accounted for"
                />
            </div>
            <div className="field">
                <button disabled={IsDisabled} type="submit" className="btn btn1">Submit</button>
            </div>
        </form>
    );
}

export default ConfirmReceiptSamples;
