import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Checkbox, Fullname, Select } from '../../Components';
import Page from '../../Components/Page';
import { getUserFacingSampleType } from '../../helpers';
import Icons from '../../Icons';
import { getSample, getDrugOptions, saveAnalysisData, getReport } from '../../requests';
import { StateContext } from '../../state';
import { Option, TabItem } from '../../types';

import './style.css';

const TABLE_ROWS = 15;

const ReportAnalysisData: FC = () => {
    const {dispatch} = useContext(StateContext);
    const {sample_id} = useParams<any>();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [sample, setSample] = useState<any>();
    const [staffName, setStaffName] = useState<string>('');
    const [isLiquid, setIsLiquid] = useState<string>('');
    const [vialWeight, setVialWeight] = useState<number | undefined>(undefined);
    const [drugs, setDrugs] = useState<any[]>([]);
    const [notes, setNotes] = useState<string>('');
    const [drugItems, setDrugItems] = useState<Option[]>([]);
    const [noDrugs, setNoDrugs] = useState<boolean>(false);

    // Computed values. Could be memoized if there is a performance gain needed.
    const percentSum: number = drugs.reduce((sum, curDrug) => sum + parseFloat(curDrug?.percent ?? 0), 0);
    const weightSum: number = drugs.reduce((sum, curDrug) => sum + parseFloat(curDrug?.weight ?? 0), 0);
    const sampleWeight = (vialWeight !== undefined && vialWeight >= 0) ? parseFloat(((vialWeight - sample.empty_weight) * 1000).toFixed(4)) : "";
    const invalidPercentSum = percentSum > 100;
    const invalidWeightSum = !!weightSum && (!sampleWeight || weightSum > sampleWeight);
   
    
    const loadOptions = async () => {
        const data = await getDrugOptions();
        const items = data.data.map((d: any) => ({
            id: d.id,
            text: d.name,
            report_to_public: d.report_to_public,
            alias: d.alias,
        }));
        setDrugItems(items);
    }

    const fillDrugs = (_drugs: any[] = []) => {
        const elements = _drugs.length;
        if (elements < TABLE_ROWS) {
            for(let i=elements; i < TABLE_ROWS; i++) {
                _drugs.push({})
            }
            setDrugs(_drugs);
        }
    }

    useEffect(() => {
        setNoDrugs(drugs.filter(d => d.name).length === 0);
    }, [drugs])

    useEffect(() => {
        setLoading(true);
        loadOptions().then(() => {
            fillDrugs();
            getSample(sample_id).then(res => {
                const _sample = res.data.results[0]
                setSample(_sample);
                getReport(sample_id).then(res => {
                    setNotes(res.data.notes);
                    setIsLiquid(res.data.is_liquid ? "Yes" : "No")
                    const draftDrugs: any[] = [];
                    res.data.drugs_found.forEach((d: any, index: number) => {
                        draftDrugs[index] = {
                            item: d.drug ? {...d.drug, text: d.drug.name} : {id: -1, text: d.custom_drug},
                            name: d.drug ? d.drug.name : d.custom_drug,
                            weight: d.weight,
                            percent: d.percent,
                        }
                    })
                    setVialWeight(+parseFloat(_sample.empty_weight + res.data.weight).toFixed(4))
                    fillDrugs(draftDrugs);
                }).catch(() => {
                    // No report, Do nothing
                }).finally(() => {
                    setLoading(false);
                })
            }).catch(()=>{
                setLoading(false);
                history.goBack();
            })
        });
    }, [sample_id, history])

    useEffect(() => {
        if (invalidPercentSum) {
            dispatch({type: "showAlert", payload: {
                error: true,
                message: 'Sum of drug percentages cannot exceed 100.',
            }});
        }
    }, [invalidPercentSum, dispatch])

    useEffect(() => {
        if (invalidWeightSum) {
            dispatch({type: "showAlert", payload: {
                error: true,
                message: 'Sum of quantities cannot exceed the sample weight.',
            }});
        }
    }, [invalidWeightSum, dispatch])

    const getValue = useCallback((index: number, key: string) => {
        const drug = drugs[index];
        if (drug) {
            const value = drug[key];
            if (value) {
                return value;
            }
        }
        return "";
    }, [drugs])

    const submitHandler = (e: any) => {
        e.preventDefault();
        save();
    }

    const save = async(draft: boolean = false, next: any = null) => {
        try {
            setLoading(true);
            const reportWeight = vialWeight ? parseFloat((vialWeight - sample.empty_weight).toFixed(4)) : undefined
            await saveAnalysisData(sample_id, staffName, isLiquid === "Yes", reportWeight, noDrugs ? [] : drugs, notes, draft);
            if (next === null) {
                dispatch({
                    type: "showAlert",
                    payload: {
                        message: `${sample_id} Sample Status Updated`,
                        status: "Status: Results Reported"
                    }
                })
                history.replace(`/result/${sample_id}`, {tab: TabItem.SamplesToReport})
            } else {
                next();
            }
        } catch (err: any) {
            dispatch({type: "showAlert", payload: {
                error: true,
                message: err.message,
            }});
        } finally {
            setLoading(false);
        }
    }

    if (loading && !sample) {
        return ( 
            <Page full title="Report Analysis Data" sample_id={sample_id} onCancel={() => history.goBack()}>
                <h1 className="pal">Loading...</h1>
            </Page>
        )
    }

    const IsDisabled: boolean = (
        loading || 
        !staffName || 
        drugs.some(d => ((!d.name && d.percent) || (d.name && !d.percent))) ||
        (sample.sample_type !== "PARAPHERNALIA" && (
            !isLiquid ||
            isLiquid === "" ||
            (isLiquid === "No" && (vialWeight === undefined || vialWeight < 0))
        )) ||
        invalidPercentSum ||
        invalidWeightSum 
    );

    const selectChange = (v: any, index: number) => {
        setDrugs((old: any) =>{
            if (!v) {
                old[index] = {}
                return [...old];
            }
            const x = old.find((d: any) => d.item === v);
            if (!x) {
                old[index].item = v;
                old[index].name = v ? v.text : '';
                setNoDrugs(false);
            }
            return [...old];
        })
    }

    const handlePaste = (event: any, index: number, setIsOpen: any) => {
        event.preventDefault();
        setIsOpen && setIsOpen(false);

        const paste: string = event.clipboardData.getData('text');
        const lines: string[] = paste.split("\n");

        setDrugs((old: any) => {
            for(let i=0; i<lines.length; i++) {
                const idx = index + i;
                const line = lines[i];
                const cells = line.split("\t").map(cell => cell.trim());
                
                const searchKey = cells[0].toLowerCase().trim();
                if (searchKey.length < 1) continue

                let hasDrug = drugItems.find((d: any) => d.text.toLowerCase().trim() === searchKey)
                if (!hasDrug) {
                    hasDrug = drugItems.find((d: any) => d.alias?.toLowerCase().trim().split(";").map((a: string) => a.trim()).includes(searchKey))
                }

                if (hasDrug) {
                    selectChange(hasDrug, idx)
                } else {
                    old[idx].item = {id: -1, text: cells[0]};
                    old[idx].name = cells[0];
                }

                if (cells.length > 1) {
                    const value = parseFloat(cells[1]);
                    if (!isNaN(value)) {
                        old[idx].percent = value.toFixed(2);
                    }
                }
                if (cells.length > 2) {
                    const value = parseFloat(cells[2]);
                    if (!isNaN(value)) {
                        old[idx].weight = value.toFixed(4);
                    }
                }
            }    
            return [...old];
        })
    }

    const doPaste = (event: any, key: string, drug: any, index: number) => {
        event.preventDefault();
        const paste: string = event.clipboardData.getData('text');
        const lines: string[] = paste.split("\n");
        
        setDrugs((old: any) => {
            for(let i=0; i<lines.length; i++) {
                const idx = index + i;
                const line = lines[i];
                const cells = line.split("\t").map(cell => cell.trim());

                if (cells.length >= 1) {
                    const value = parseFloat(cells[0]);
                    if (!isNaN(value)) {
                        old[idx][key] = value.toFixed(key === "percent" ? 2 : 4);
                    }
                }
                
                if (cells.length >= 2 && key === "percent") {
                    const value = parseFloat(cells[1]);
                    if (!isNaN(value)) {
                        old[idx].weight = value.toFixed(4);
                    }
                }
            }    
            return [...old];
        })
    }

    const fixDecimal = (key: string, index: number, fractionDigits: number = 2) => {
        setDrugs((old: any) =>{
            if (old[index][key]) {
                old[index][key] = parseFloat(old[index][key]).toFixed(fractionDigits);
            }
            return [...old];
        })
    }

    return (
        <Page mainClass="reportdata" full title="Report Analysis Data" sample_id={sample_id} onCancel={() => history.goBack()}>
            <form className="receiptsamples">
                <div className="field w80">
                    <h6>Staff Member Name</h6>
                    <label className="label">
                        Full name of staff that analyzed this sample: <span className="required">*</span>
                    </label>
                    <Fullname onChange={(fullname: string) => setStaffName(fullname)} />
                </div>
                <hr />
                <div className="field">
                    <h6>Collection Data</h6>
                    <div className="coldata">
                        <div style={{marginRight: '6.25rem'}}>
                            <div className="text">Sample ID:</div>
                            <div className="bold mb">{ sample_id }</div>
                        </div>
                        <div>
                            <div className="text">Sample Type:</div>
                            <div className="bold mb">{getUserFacingSampleType(sample.sample_type)}</div>
                        </div>
                    </div>
                    <div className="text">Expected Drugs:</div>
                    <div className="bold mb">
                        {sample.expected_drugs.map((ed: string, index: number) => {
                            return <div key={index}>{ed}</div>
                        })}
                    </div>
                </div>
                <hr />
                {sample.sample_type === "SUBSTANCE" &&
                    <>
                        <div className="field">
                            <h6>Substance Sample Details</h6>
                        </div>
                        <div className="field">
                            <label className="label">Is this substance a liquid? <span className="required">*</span></label>
                            <Select 
                                items={[{id: 0, text: 'No'}, {id: 1, text: 'Yes'}]}
                                onChange={(item: any) => {
                                    if (item) {
                                        setIsLiquid(item.text)
                                    } else {
                                        setIsLiquid("");
                                    }
                                }}
                                value={isLiquid ? {id: isLiquid === "Yes" ? 1 : 0, text: isLiquid === "Yes" ? 'Yes' : 'No'} : undefined}
                                mainClass="mini"
                                searchable={false}
                                required
                            />
                        </div>
                        <div className="field">
                            <div className={`details w80 ${isLiquid !== "No" ? 'liquid' : ''}`}>
                                <div className="winp">
                                    <label>Vial weight (with sample):</label>
                                    <div className="input">
                                        <input 
                                            type="number"
                                            step="0.0001"
                                            disabled={loading || isLiquid !== "No"}
                                            value={vialWeight}
                                            onWheel={(e: any) => e.target.blur()}
                                            onChange={e => {
                                                if (!isNaN(parseFloat(e.target.value))) {
                                                    setVialWeight(+parseFloat(e.target.value).toFixed(4))
                                                } else {
                                                    setVialWeight(undefined);
                                                }
                                            }}
                                        />
                                        <p>g</p>
                                    </div>
                                </div>
                                <div className="winp">
                                    <label>Vial weight (empty):</label>
                                    <div className="input">
                                        <div className="sign signleft">-</div>
                                        <input 
                                            type="number" 
                                            step="0.0001" 
                                            readOnly 
                                            value={parseFloat((sample.empty_weight).toFixed(4))}
                                        />
                                        <p>g</p>
                                        <div className="sign signright">=</div>
                                    </div>
                                </div>
                                <div className="winp">
                                    <label>Sample Weight:</label>
                                    <div className="input">
                                        <input
                                            type="number"
                                            step="0.0001"
                                            readOnly
                                            value={sampleWeight}
                                        />
                                        <p>mg</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </>
                }
                <div className="field">
                    <h6>Substances Found In Sample</h6>
                    <label className="label">Paste or enter the lab report into the field below.</label>
                    <div className="tableContainer mb-2">
                        <table className="drugfoundtable">
                            <thead>
                                <tr style={{height: '47px'}}>
                                    <th style={{width: '10px'}}></th>
                                    <th style={{width: '29%'}}>Drug Name</th>
                                    <th style={{width: '15%'}}>Relative %</th>
                                    <th style={{width: '17%'}}>Quantity (mg)</th>
                                    <th style={{width: '8%'}}>Public</th>
                                    <th>Alias</th>
                                </tr>
                            </thead>
                            <tbody>
                                {drugs.map((drug: any, index: number)=>{
                                    return (
                                        <tr key={index}>
                                            <td className="rowindex">{index + 1}</td>
                                            <td className="entry">
                                                <Select 
                                                    items={drugItems}
                                                    onChange={(v: any) => selectChange(v, index)}
                                                    value={drug.item}
                                                    mainClass="whiteback"
                                                    nochevron
                                                    canAdd
                                                    onPaste={(event: any, setIsOpen: any) => handlePaste(event, index, setIsOpen)}
                                                />
                                            </td>
                                            <td className="entry input">
                                                <input type="number" 
                                                    step="0.001" 
                                                    max="100" 
                                                    className="entryinput" 
                                                    onWheel={(e: any) => e.target.blur()}
                                                    onChange={e => {
                                                        setDrugs((old: any) =>{
                                                            old[index].percent = e.target.value;
                                                            return [...old];
                                                        });
                                                    }} 
                                                    value={noDrugs ? "" : getValue(index, 'percent')}
                                                    onBlur={() => fixDecimal("percent", index)} 
                                                    onPaste={event => doPaste(event, 'percent', drug, index)}
                                                />
                                            </td>
                                            <td className="entry input">
                                                <input type="number" 
                                                    step="0.0001"
                                                    className="entryinput"
                                                    onWheel={(e: any) => e.target.blur()}
                                                    onChange={e => {
                                                        setDrugs((old: any) =>{
                                                            old[index].weight = e.target.value;
                                                            return [...old];
                                                        })
                                                    }}
                                                    value={noDrugs ? "" : getValue(index, 'weight')}
                                                    onBlur={() => fixDecimal("weight", index, 4)} 
                                                    onPaste={event => doPaste(event, 'weight', drug, index)}
                                                />
                                            </td>
                                            <td>
                                                {(drug.item && (drug.item.id === -1 || drug.item.report_to_public)) &&
                                                    <Icons.IsPublic />
                                                }
                                            </td>
                                            <td>
                                                {drug.item ? drug.item.alias ? drug.item.alias : "---" : ""}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div style={{width: '250px'}}>
                        <Checkbox
                            checked={noDrugs}
                            onChange={(v: boolean) => {
                                if (v) {
                                    fillDrugs([]);
                                }
                                setNoDrugs(v);
                            }}
                            label="No Substances Found"
                        />
                    </div>
                </div>
                <hr />
                <div className="field w80">
                    <label className="label">Notes:</label>
                    <textarea
                        disabled={loading}
                        style={{width: '100%'}}
                        value={notes}
                        onChange={e => setNotes(e.target.value)}
                    />
                </div>
                <div className="field buttons" style={{justifyContent: 'flex-start'}}>
                    <button type="button" 
                        disabled={loading} 
                        className="btn btn2 mr-1" 
                        onClick={() => save(true, () => {
                            history.push(`/prepare-for-transport`, {
                                checked_samples: [sample],
                                sample: sample_id,
                            })
                        })}>Prepare For Transport</button>
                    <button type="button" disabled={loading} className="btn btn2 mr-1" onClick={() => save(true)}>Save As Draft</button>
                    <button type="button" disabled={IsDisabled} onClick={submitHandler} className="btn btn1">Submit Analysis Data</button>
                </div>
            </form>
        </Page>
    );
}

export default ReportAnalysisData;
