import React, {FC, useContext, useState, FormEvent} from 'react';
import { useHistory } from "react-router-dom";

import {StateContext} from '../../state';
import {login, parseJwt} from '../../requests';
import Icons from '../../Icons';

import './style.css';

const Login: FC = () => {
    const {dispatch} = useContext(StateContext);
    const history = useHistory();
    
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>();

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrors(null);
        try {
            setLoading(true);
            const {data} = await login(email, password);
            const loginData = {
                ...data,
                ...parseJwt(data.access)
            }
            dispatch({type: 'login', payload: loginData});
            history.replace("/");    
        } catch (err: any) {
            dispatch({type: 'showAlert', payload: {
                message: err.message,
                error: true,
            }});
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="loginpage">
            <div className="loginbox">
                <Icons.LoginTopBorder className="topborder" />
                <div className="padding">
                    <Icons.LoginLogo className="loginlogo" />
                    <form onSubmit={submitHandler}>
                        <div className="field">
                            <input type="email" placeholder="Username" required disabled={loading} value={email} onChange={e => {
                                setEmail(e.target.value);
                                setErrors((err: any) => ({...err, email: undefined}))
                            }} />
                            {errors && errors.email &&
                                <p>{errors.email}</p>
                            }
                        </div>
                        <div className="field">
                            <input type="password" placeholder="Password" required disabled={loading} value={password} onChange={e => {
                                setPassword(e.target.value);
                                setErrors((err: any) => ({...err, password: undefined}))
                            }} />
                            {errors && errors.password &&
                                <p>{errors.password}</p>
                            }
                        </div>
                        <div className="button">
                            <button className="btn btn1" disabled={loading}>Sign In</button>
                        </div>
                    </form>
                    <hr />
                    <p>
                        Having trouble logging in?<br />
                        <br />
                        Contact an administrator at:<br />
                        <a href="mailto:drugchecking@cdpe.org">drugchecking@cdpe.org</a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Login;
