import moment from 'moment';
import React, { FC } from 'react';

import './style.css';

type IProps = {
    onDone: Function;
    form: any;
}

const ReturningResult: FC<IProps> = (props: IProps) => (
    <>
        <p style={{ marginBottom: '2.1875rem' }}>
            Here's when drug checking results will be available. You may wish to record this information on a reminder card, especially if the sample was collected from a service user and they're returning in person or are going to call or email you for results.
        </p>
        <div className="remindercard">
            {props.form.result &&
                <>
                    <div className="text">Date to return:</div>
                    <div className="bold mb">{moment(props.form.result.return_date).format("dddd, MMMM D, YYYY")}</div>
                    <div className="text">Any time after:</div>
                    <div className="bold mb">12:00 PM</div>
                    <div className="text">Sample ID:</div>
                    <div className="bold mb">{props.form.sample_id.text}</div>
                </>
            }
        </div>
        <div className="review banner">
            <div className="bannercontent">
                <button className="btn btn1" onClick={() => props.onDone()}>Done</button>
            </div>
        </div>
    </>
)

export default ReturningResult;
