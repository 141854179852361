import React, {FC, useState, useEffect, useCallback} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

import { CustodyLogs, NavigationBar, NavigationTab, SampleStatus } from '../../Components';
import { getPerms, getReport, getSample } from '../../requests';
import { TabItem } from '../../types';
import DrugsFound from './DrugsFound';
import { getUserFacingSampleType, getCollectedFrom, getShareResult, removeUnderline, getYesNoDontKnowItem } from '../../helpers';
import './style.css';
import Icons from '../../Icons';

type ILocation = {
    state: any,
    tab?: TabItem
}

type IProps = {
    children?: any;
    onSample?: (smpl: any) => void;
    onReport?: (report: any) => void;
}

const SampleDetailView: FC<IProps> = (props: IProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [sample, setSample] = useState<any>({});
    const [activeTab, setActiveTab] = useState<TabItem>(TabItem.CollectionData);
    const [report, setReport] = useState<any>();
    const ilocation = useLocation<ILocation>();
    const {sample_id} = useParams<any>();
    const history = useHistory();
    const {performs_analysis, performs_collection} = getPerms();
    
    useEffect(() => {
        function handleChange(event: any) {
            event.preventDefault();
            history.push("/", {tab: ilocation.state ? ilocation.state.tab : undefined})
        }
        window.addEventListener('popstate', handleChange)
        return () => window.removeEventListener('popstate', handleChange)
    }, [history, ilocation])

    const getSampleByID = useCallback(async (onDone?: any) => {
        let _sample: any;
        try {
            setError('');
            setLoading(true);
            const {data} = await getSample(sample_id);
            if (data.count > 0) {
                _sample = data.results[0]
                setSample(_sample);
                props.onSample && props.onSample(_sample);
            } else {
                throw new Error(`Sample with id ${sample_id} not found`)
            }
        } catch (er: any) {
            setError(er.message);
        } finally {
            setLoading(false);
        }
        getReport(sample_id).then(res => {
            setReport(res.data);
            props.onReport && props.onReport(res.data);
            if (res.data.drugs_found.length > 0 || res.data.reported_at || res.data.is_draft) {
                setActiveTab(TabItem.AnalysisResult);
            }
            onDone && onDone()
        }).catch(() => {
            if (_sample.not_analyzed) {
                setActiveTab(TabItem.AnalysisResult);
            }
        })
    // eslint-disable-next-line
    }, [sample_id, props.onSample, props.onReport])

    useEffect(() => {
        getSampleByID();

        const getSampleEvent = (event: any) => getSampleByID(event.detail)
        window.addEventListener("getSample", getSampleEvent)
        return () => window.removeEventListener("getSample", getSampleEvent)
    // eslint-disable-next-line
    }, [])

    if (error) {
        return <div className="pal"><h2>Error: {error}</h2> <button className="btn btn2" onClick={() => getSampleByID()}>Try Again</button></div>
    }

    if (loading) {
        return (<h2 className="pal">Loading...</h2>);
    }

    return (
        <>
            <div className="sampleviewhead">
                <div className="sampleviewheadtop">
                    <div className="sampleviewback">
                        <Icons.Back onClick={() => history.push("/", {tab: ilocation.state ? ilocation.state.tab : undefined})} />
                        <h2 className="title mb-0">Sample <span className="sample_id">{sample_id}</span></h2>
                    </div>
                    {props.children &&
                        <div className="buttoncontainer">
                            {props.children}
                        </div>
                    }
                </div>
                <hr />
                <div className="summary">
                    <div className="mr-3">
                        <SampleStatus sample={sample} hideUpdate />
                    </div>
                    <div className="date mr-3"><Icons.Location /> &nbsp;Analysis Site:&nbsp;<b>{sample.analysis_site_name}</b></div>
                    {performs_collection && sample.return_date && !sample.not_analyzed && !report &&
                        <div className="date mr-3"><span>Results will be reported:</span>&nbsp;<b>{moment(sample.return_date).format("MMMM D, YYYY,")} 12:00 PM</b></div>
                    }
                    <div className="date"><span>Last updated:</span>&nbsp;<b>{moment(sample.last_update).format("MMMM D, YYYY, h:mm A")}</b></div>
                </div>
            </div>
            <div className='sampleviewcontent'>
                <div className="col left">
                    {(report || sample.not_analyzed) &&
                        <div className="mb-2">
                            <NavigationBar>
                                <NavigationTab 
                                    label="Analysis Results"
                                    active={activeTab === TabItem.AnalysisResult} 
                                    onClick={() => setActiveTab(TabItem.AnalysisResult) } />
                                <NavigationTab 
                                    label="Collection Data"
                                    active={activeTab === TabItem.CollectionData}
                                    onClick={() => setActiveTab(TabItem.CollectionData) } />
                            </NavigationBar>
                        </div>
                    }
                    {!report && !sample.not_analyzed &&
                        <div className="title">Collection Data</div>
                    }
                    {((!report && !sample.not_analyzed) || activeTab === TabItem.CollectionData) &&
                        <div className="withscroll">
                            <div className="field">
                                <div className="text">Sample is collected from:</div>
                                <div className="bold mb">{ getCollectedFrom(sample.collected_from) }</div>
                            </div>
                            {sample.collected_from === "STAFF_AGENCY" &&
                                <div className="field">
                                    <div className="text">Name of agency this sample is coming from:</div>
                                    <div className="bold mb">{ sample.agency_name ? sample.agency_name : '-' }</div>
                                </div>
                            }
                            <div className="field">
                                <div className="text">Have you used this drug checking service before?</div>
                                <div className="bold mb">{ sample.service_used_before !== null ? sample.service_used_before ? 'Yes' : 'No' : '-' }</div>
                            </div>
                            <div className="field">
                                <div className="text">Have you previously accessed harm reduction services, such as drug checking or supervised consumption?</div>
                                <div className="bold mb">{ sample.previously_accessed !== null ? sample.previously_accessed ? 'Yes': 'No' : '-' }</div>
                            </div>
                            <div className="field">
                                <div className="text">Sample type:</div>
                                <div className="bold mb">{getUserFacingSampleType(sample.sample_type)}</div>
                            </div>
                            {sample.sample_type !== "SUBSTANCE" &&
                                <div className="field">
                                    <div className="text">Has this equipment been reused?</div>
                                    <div className="bold mb">{sample.paraphernalia_reuse !== null ? getYesNoDontKnowItem(sample.paraphernalia_reuse)?.text : '-'}</div>
                                </div>
                            }
                            <div className="field">
                                <div className="text">Sample ID:</div>
                                <div className="bold mb">{sample.sample_id}</div>
                            </div>
                            {sample.collected_from === "USER" && sample.sample_type === "SUBSTANCE" &&
                                <div className="field">
                                    <div className="text">Did you use this drug prior to submitting this sample?</div>
                                    <div className="bold mb">{sample.already_used ? removeUnderline(sample.already_used) : '-'}</div>
                                </div>
                            }
                            <div className="field">
                                <div className="text">What was the sample purchased as?</div>
                                <div className="bold mb">{sample.expected_drugs && sample.expected_drugs.map((ed: any, index: number)=> {
                                    return <div key={index}>{ed}</div>
                                })}</div>
                            </div>
                            {sample.collected_from === "USER" &&
                                <div className="field">
                                    <div className="text">Where did this sample come from?</div>
                                    <div className="bold mb">{sample.origin ? sample.origin : '-'}</div>
                                </div>
                            }
                            <div className="field">
                                <div className="text">Was this sample associated with an overdose or other unpleasant or abnormal effects?</div>
                                <div className="bold mb">{(sample.sample_effect && sample.sample_effect.length > 0) ? sample.sample_effect.map((ed: any, index: number)=> {
                                    return <div key={index}>{ed}</div>
                                }) : '-'}</div>
                            </div>
                            {sample.collected_from === "USER" &&
                                <div className="field">
                                    <div className="text">Why are you checking this sample?</div>
                                    <div className="bold mb">{(sample.checking_reasons && sample.checking_reasons.length > 0) ? sample.checking_reasons.map((ed: any, index: number)=> {
                                        return <div key={index}>{ed}</div>
                                    }) : '-'}</div>
                                </div>
                            }
                            {sample.collected_from === "USER" &&
                                <div className="field">
                                    <div className="text">Are you planning to wait for your drug checking results before using this drug, using it again, or selling it?</div>
                                    <div className="bold mb">{sample.wait_for_results ? removeUnderline(sample.wait_for_results) : '-'}</div>
                                </div>
                            }
                            <div className="field">
                                <div className="text">Sample colour:</div>
                                <div className="bold mb">{(sample.colour && sample.colour.length > 0) ? sample.colour.map((ed: any, index: number)=> {
                                    return <div key={index}>{ed}</div>
                                }) : '-'}</div>
                            </div>
                            <div className="field">
                                <div className="text">Sample texture:</div>
                                <div className="bold mb">{(sample.texture && sample.texture.length > 0) ? sample.texture.map((ed: any, index: number)=> {
                                    return <div key={index}>{ed}</div>
                                }) : '-'}</div>
                            </div>
                            <div className="field">
                                <div className="text">How do you wish to receive your results?</div>
                                <div className="bold mb">{getShareResult(sample.results_provision)}</div>
                            </div> 
                        </div>
                    }
                    {!report && sample.not_analyzed && activeTab === TabItem.AnalysisResult &&
                        <div className="notAnalyzed">
                            This sample could not be analyzed because:
                            <p>{sample.not_analyzed}</p>
                        </div>
                    }
                    {report && !sample.not_analyzed && activeTab === TabItem.AnalysisResult &&
                        <div className="withscroll">
                            <div className="row3col">
                                <div className="field">
                                    <div className="text">Sample ID:</div>
                                    <div className="bold mb">{sample.sample_id}</div>
                                </div>
                                <div className="field">
                                    <div className="text">Date Checked:</div>
                                    <div className="bold mb">{report && report.reported_at ? moment(report.reported_at).format("MMMM D, YYYY") : 'In progress'}</div>
                                </div>
                                <div className="field">
                                    <div className="text">Sample Type:</div>
                                    <div className="bold mb">{getUserFacingSampleType(sample.sample_type)}</div>
                                </div>
                            </div>
                            <div className="row3col">
                                <div className="field">
                                    <div className="text">Expected Drug(s):</div>
                                    <div className="bold mb">{sample.expected_drugs.map((ed: any, index: number) => {
                                        return (<div key={index}>{ed}</div>)
                                    })}</div>
                                </div>
                                {sample.sample_type === "SUBSTANCE" && report && !report.is_liquid && ((!performs_analysis && report.drugs_found.some((d: any) => d.weight && parseFloat(d.weight) > 0)) || performs_analysis) &&
                                    <div className="field">
                                        <div className="text">Sample Weight:</div>
                                        <div className="bold mb">
                                            {!performs_analysis && <>10.00 mg (10% of a point)</>}
                                            {performs_analysis && <>{(parseFloat(report.weight) * 1000).toFixed(2)} mg</>}
                                        </div>
                                    </div>
                                }
                                {report && report.is_liquid &&
                                    <div className="field">
                                        <div className="text">Sample State:</div>
                                        <div className="bold mb">Liquid</div>
                                    </div>
                                }
                            </div>
                            <DrugsFound
                                isDraft={report && report.is_draft}
                                drugs={report.drugs_found}
                                weight={report.weight * 1000}
                                isLiquid={report && report.is_liquid}
                                performsCollection={performs_collection}
                            />
                            <div className="drugnotes">
                                <div>Notes</div>
                                <p>{report.notes}</p>
                            </div>
                        </div>
                    }
                </div>
                <div className="col right">
                    <div className="title">Custody Log</div>
                    <div className="withscroll">
                        <CustodyLogs sample_id={sample_id} />
                    </div>
                </div>  
            </div>
        </>
    );
}

export default SampleDetailView;
