import axios from 'axios';

import { TabItem } from './types';

const API_URL = process.env.NODE_ENV === "development" ? "https://staging-api.earnest.community" : process.env.REACT_APP_API_URL;
// "http://localhost:8000"

axios.interceptors.response.use(
    response => response,
    error => {
        const invalidToken =    error.response && 
                                error.response.status === 401 && 
                                error.response.data.detail && 
                                [
                                    "Given token not valid for any token type",
                                    "User not found",
                                    "Authentication credentials were not provided."
                                ].includes(error.response.data.detail);
        if (invalidToken) {
            window.localStorage.clear();
            window.location.replace("/");
            return;
        }
        
        let message = "Server Error";

        if (error.response) {
            let err = error.response.data;
            if (err.detail) {
                message = err.detail;
            } else if (err.errors) {
                const first = Object.keys(err.errors)[0];
                message = err.errors[first];
            } else if (err.error) {
                message = err.error;
            }
        }

        error.message = message;
        throw new Error(error);
    }
);

const getToken = () => {
    let user: any = localStorage.getItem("user");
    if (!user) return;
    user = JSON.parse(user);
    if (!user) return;
    return user.access;
}

const getRefresh = () => {
    let user: any = localStorage.getItem("user");
    if (!user) return null;
    user = JSON.parse(user);
    if (!user) return null;
    return user.refresh;
}

export const getPerms = () => {
    let user: any = localStorage.getItem("user");
    user = JSON.parse(user);
    return {
        performs_collection: user.performs_collection,
        performs_analysis: user.performs_analysis,
    };
}

export const parseJwt = (token: string) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};
  
export const login = (email: string, password: string) => axios({
    method: 'POST',
    url: `${API_URL}/auth/token/`,
    data: {
        email,
        password
    }
})

export const refreshToken = () => axios({
    method: 'POST',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    url: `${API_URL}/auth/token/refresh/`,
    data: {
        refresh: getRefresh()
    }
})

export const getCollectedFormFields = () => axios({
    method: 'GET',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    url: `${API_URL}/collection/survey-options/`, 
})

const getModifiedForm = (form: any) => {
    const modifiedForm: any = {};
    Object.keys(form).forEach((key: string) => {
        if (Array.isArray(form[key])) {
            form[key] = form[key].map((v: any) => {
                if (v.other) {
                    return `${v.main}: ${v.text}`;
                }
                return v.text;
            });
        } 

        switch(key) {
            case 'collected_from':
                let collected_from;
                switch(form[key].id) {
                    case 'service_user':
                        collected_from = "USER"
                    break;
                    case 'staff_behalf':
                        collected_from = "STAFF_BEHALF_USER"
                    break;
                    case 'staff_behind':
                        collected_from = "STAFF_BEHIND"
                    break;
                    case 'staff_from':
                        collected_from = "STAFF_AGENCY"
                    break;
                }
                modifiedForm[key] = collected_from;
            break;
            case "sample_type":
                modifiedForm[key] = form[key].text === "Drug (vial)" ? "SUBSTANCE" : "PARAPHERNALIA";
            break;
            case "come_from":
                if (form.come_from_other) {
                    modifiedForm.origin = `Other: ${form.come_from_other}`;
                } else {
                    modifiedForm.origin = form.come_from.text;
                }
            break;
            case "results_provision":
                let results_provision = "INTERNALLY";
                if (form[key].text === "In person") {
                    results_provision = "IN_PERSON"
                } else if (form[key].text === "By phone") {
                    results_provision = "PHONE";
                } else if (form[key].text === "By email") {
                    results_provision = "EMAIL";
                }
                modifiedForm.results_provision = results_provision;
            break;
            case "used_before":
            case "accessed_before":
                const val = form[key] === undefined ? undefined : form[key].text === "No" ? false : form[key].text === "Yes" ? true : undefined;
                switch(key) {
                    case 'accessed_before':
                        modifiedForm.previously_accessed = val;
                    break;
                    case 'used_before':
                        modifiedForm.service_used_before = val;
                    break;
                }
            break;
            case "paraphernalia_reuse":
            case "wait_for_results":
            case "already_used":
                let value = undefined;
                if (form[key]) {
                    switch(form[key].text) {
                        case 'Yes':
                            value = "YES";
                        break;
                        case 'No':
                            value = "NO";
                        break;
                        case "Don't have any of this drug left":
                            value = "DONT_HAVE_ANY_OF_THIS_DRUG_LEFT";
                        break;
                        default:
                            value = "DONT_KNOW";
                    }    
                }
                modifiedForm[key] = value;
            break;
            case "checking_purpose":
                modifiedForm.checking_reasons = form[key];
            break;
            case "sample_colours":
                modifiedForm.colour = form[key];
            break;
            case "sample_effects":
                modifiedForm.sample_effect = form[key];
            break;
            case "sample_textures":
                modifiedForm.texture = form[key];
            break;
            case "sample_id":
                modifiedForm.sample_id = form[key].text;
            break;
            case "origin":
                modifiedForm.origin = form.origin.other ? `Other: ${form.origin.text}` : form.origin.text;
            break;
            case "agency":
                modifiedForm.agency_name = form[key];
            break;
            default:
                modifiedForm[key] = form[key];
        }
    })
    return modifiedForm;
}

export const saveCollection = (form: any) => axios({
    method: 'POST',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    url: `${API_URL}/samples/`,
    data: getModifiedForm(form)
});

export const getSamples = (
    page: number,
    sample_id: string,
    activeTab: TabItem,
    sortBy: string = 'sample_id',
    sortOrder: 'asc' | 'desc' = 'asc',
    perPage: number = 30,
) => {
    let state = undefined;
    switch(activeTab) {
        case TabItem.ResultsToShare:
            state = 'ResultsToShare';
            break;
        case TabItem.SamplesToTransport:
            state = 'FILLED';
            break;
        case TabItem.SamplesToReceive:
            state = 'IN_TRANSPORT';
            break;
        case TabItem.SamplesToReport:
            state = 'IN_ANALYSIS';
            break;
        case TabItem.SamplesToDispose:
            state = 'SamplesToDispose';
            break;
    }
    let url = `${API_URL}/samples/?limit=${perPage}&offset=${(page - 1) * perPage}&sort_by=${sortBy}&order_by=${sortOrder}`;
    if (state) {
        url += `&state=${state}`;
    }
    if (sample_id) {
        url += `&sample_id=${sample_id}`
    }
    return axios({
        method: 'GET',
        headers: {
            authorization: `Bearer ${getToken()}`
        },
        url
    })
}

export const getSample = (sample_id: string) => axios({
    method: 'GET',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    url: `${API_URL}/samples/?sample_id_iexact=${sample_id}&limit=1`, 
})

export const getCustodyLogs = (sample_id: string) => axios({
    method: 'GET',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    url: `${API_URL}/custodylogs/logs/?sample_id=${sample_id}`, 
})


export const deleteSample = (sample_id: string, name: string, reason: string) => axios({
    method: 'DELETE',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    url: `${API_URL}/samples/${sample_id}/`, 
    data: {
        staff: name,
        reason
    }
})

export const confirmReceiptSamples = (sample_ids: string[], courierName: string, fullname: string) => axios({
    method: 'POST',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    url: `${API_URL}/packages/accept/`,
    data: {
        sample_ids,
        transported_by: courierName,
        accepted_by: fullname,
    } 
})

export const reportMissingSamples = (sample_ids: string[], fullname: string | undefined) => axios({
    method: 'POST',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    url: `${API_URL}/samples/report-missing/`,
    data: {
        samples: sample_ids,
        fullname
    } 
})

export const disposeSamples = (sample_ids: string[], staffFullName: string, witnessName: string, why?: string) => {
    const data: any = {
        samples: sample_ids,
        staff: staffFullName,
        witness: witnessName,
    }
    if (why) {
        data.why = why;
    }
    return axios({
        method: 'POST',
        headers: {
            authorization: `Bearer ${getToken()}`
        },
        url: `${API_URL}/analysis/dispose/`,
        data
    })
}

export const prepareTransport = (dry_run: boolean, sample_ids: string[], fullname?: string, dest_site?: string, why?: string) => {
    const {performs_collection} = getPerms();

    const data: any = {
        samples: sample_ids,
        reason: performs_collection ? "FROM_COLLECTION" : "REQUIRES_FURTHER_ANALYSIS",
        dry_run,
        why: why && why.length > 0 ? why : null,
        person: fullname ? fullname : 'Person',
    }

    if (dest_site) {
        data.dest_site = dest_site;
    }

    return axios({
        method: 'POST',
        headers: {
            authorization: `Bearer ${getToken()}`
        },
        url: `${API_URL}/packages/prepare/`,
        data
    })
}

export const shareResult = (sample_id: string, form: any) => axios({
    method: 'POST',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    url: `${API_URL}/collection/results/${sample_id}/share/`,
    data: form
})

export const couldNotShareResult = (sample_id: string, form: any) => axios({
    method: 'POST',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    url: `${API_URL}/collection/results/${sample_id}/not-share/`,
    data: form
})

export const downloadReport = (sample_id: string) => axios({
    method: 'GET',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    responseType: 'blob',
    url: `${API_URL}/collection/results/${sample_id}/download/`,
})

export const getRoutes = (time: string[] | undefined) => {
    const queryString = time !==undefined ? `?hour=${time[0]}&minute=${time[1]}` : ''
    return axios({
        method: 'GET',
        url: `${API_URL}/packages/routes/${queryString}`
    })
}

export const getCounts = () => axios({
    method: 'GET',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    url: `${API_URL}/dhp/tab-counts/`,  
})

export const getReport = (sample_id: any) => axios({
    method: 'GET',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    url: `${API_URL}/analysis/reports/${sample_id}/`,  
})

export const getDrugOptions = (name?: string) => axios({
    method: 'GET',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    url: `${API_URL}/dhp/drug/${name ? `?name=${name}`: ''}`,  
})

export const setResearchParticipation = (sample_id: any, value: boolean) => axios({
    method: 'POST',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    url: `${API_URL}/collection/${sample_id}/research_participation/`,
    data: {value}
})

export const saveAnalysisData = (sample_id: string,staffName: string, isLiquid: boolean, 
                                vialWeight: number | undefined, drugs: any[], notes: string, is_draft: boolean = false) => {
    const drugs_found = drugs.filter((d: any) => d.name && d.name.length > 0).map((d: any) => {
        const out: any = {
            weight: d.weight,
            percent: d.percent
        }
        if (d.item.id === -1) {
            out.custom_drug = d.name
        } else {
            out.drug = {
                name: d.name,
            }
        }
        return out
    });

    const form = {
        drugs_found,
        is_liquid: isLiquid,
        notes,
        sample: sample_id,
        staff: staffName,
        weight: vialWeight,
    }

    return axios({
        method: 'POST',
        headers: {
            authorization: `Bearer ${getToken()}`
        },
        url: `${API_URL}/analysis/reports/`,
        params: {is_draft},
        data: form
    })
}


export const getMySite = () => axios({
    method: 'GET',
    headers: {
        authorization: `Bearer ${getToken()}`
    },
    url: `${API_URL}/user/my-site/`,  
})
