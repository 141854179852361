import React, {FC, useContext, useState} from 'react';
import { SampleDetailView } from '..';
import { ShareAndDownload, Page } from '../../Components';
import { useHistory } from 'react-router-dom';
import { StateContext } from '../../state';
import { couldNotShareResult } from '../../requests';

const ResultShareDetailView: FC = () => {
    useHistory();
    const [, setLoading] = useState<boolean>(false);
    const {dispatch} = useContext(StateContext);

    const [report, setReport] = useState<any>();
    const [sample, setSample] = useState<any>();
    const isServiceUser = sample && sample.collected_from === 'USER'
    const isShared = sample && !!sample.shared_at

    
    async function markAsCouldNotShared(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        
        try {
            setLoading(true);
            await couldNotShareResult(sample.sample_id, {});
            dispatchEvent(new CustomEvent('getSample', {detail: () => {
                dispatch({type: "showAlert", payload: {
                    message: `${sample.sample_id} Sample Status Updated`,
                    status: 'Status: Couldn\'t Share',
                }});
            }}))
        } catch (err: any) {
            dispatch({type: "showAlert", payload: {
                error: true,
                message: err.message,
            }});
        } finally {
            setLoading(false);
        }
    }


    return (
        <Page mainClass="sampleview">
            <SampleDetailView onReport={setReport} onSample={setSample}>
            {(isServiceUser && !isShared && sample.state!=="COULD_NOT_SHARE" ) &&
                    <button
                    className="btn btn2"
                    onClick={markAsCouldNotShared}>
                        Couldn’t Share
                </button>
                }
                { sample && sample.state!=="COULD_NOT_SHARE" &&<ShareAndDownload sample={sample} report={report} />}
                
            </SampleDetailView>
        </Page>
    )
}

export default ResultShareDetailView;
