import moment from 'moment';
import React, { FC, useState, useEffect, useCallback } from 'react';
import { describeAction } from '../../helpers';

import { getCustodyLogs } from '../../requests';
import './style.css';

type IProps = {
    sample_id: string
}

const CustodyLogs: FC<IProps> = (props: IProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [logs, setLogs] = useState<any>({});

    const getCustodyLogsByID = useCallback(async () => {
        try {
            setError('');
            setLoading(true);
            const { data } = await getCustodyLogs(props.sample_id);
            setLogs(data);
        } catch (er: any) {
            setError(er.message);
        } finally {
            setLoading(false);
        }
    }, [props.sample_id])

    useEffect(() => {
        getCustodyLogsByID();
    }, [getCustodyLogsByID])

    if (error) {
        return <div><h2>Error: {error}</h2> <button className="btn btn2" onClick={() => getCustodyLogsByID()}>Try Again</button></div>
    }

    if (loading) {
        return (<h2>Loading...</h2>);
    }

    return (
        <ul className="custodylogs">
            {logs.map((log: any, index: number) => (
                <li key={index}>
                    <div className="status">{describeAction(log.action)} By:</div>
                    <div className="value">{log.staff}</div>
                    {log.site && log.action !== "DELIVERED" &&
                        <div className="value">{log.site.name}<br />{log.site.address}</div>
                    }
                    {log.time &&
                        <div className="date">{moment(log.time).format("MMMM D, YYYY, h:mm A")}</div>
                    }
                    {log.edited_at &&
                        <div className="date">Edited: {moment(log.edited_at).format("MMMM D, YYYY, h:mm A")}</div>
                    }
                </li>
            ))}
        </ul>
    );
}

export default CustodyLogs;
