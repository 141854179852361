import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Page } from '../../Components';
import Icons from '../../Icons';
import { getReport, getSample, downloadReport } from '../../requests';
import { StateContext } from '../../state';
import './style.css';

const DownloadView: FC = () => {
    const {dispatch} = useContext(StateContext);
    const [loading, setLoading] = useState<boolean>(false);
    const { sample_id } = useParams<any>();
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        getSample(sample_id).then(res => {
            if (res.data.count !== 1) {
                dispatch({type: "showAlert", payload: {
                    error: true,
                    message: `Invalid sample: ${sample_id}`,
                }});
                return history.goBack();
            }
            getReport(sample_id).catch(() => {
                dispatch({type: "showAlert", payload: {
                    error: true,
                    message: `Report ${sample_id} is not ready`,
                }});
                history.goBack();
            })
        }).finally(() => setLoading(false))
    }, [history, sample_id, dispatch]);

    let onCancel = undefined;
    if (!loading) {
        onCancel = () => history.goBack();
    }

    async function download(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        try {
            setLoading(true);
            const { data } = await downloadReport(sample_id);
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `results_${sample_id}.pdf`);
            document.body.appendChild(link);
            link.click();
        } catch (err: any) {
            dispatch({type: "showAlert", payload: {
                error: true,
                message: err.message,
            }});
        } finally {
            setLoading(false);
        }
    }

    return (
        <Page full title="Download Analysis Results" sample_id={sample_id} onCancel={onCancel} hasClose>
            <div className="box downloadbox">
                <div className="download">
                    <div className="firstsection">
                        <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2637_14106)">
                                <path d="M30.875 14.625H24.375V4.875H14.625V14.625H8.125L19.5 26L30.875 14.625ZM8.125 29.25V32.5H30.875V29.25H8.125Z" fill="#044554" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2637_14106">
                                    <rect width="39" height="39" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <div>
                            <h3>Download PDF</h3>
                            <h4>results_{sample_id}.pdf</h4>
                        </div>
                    </div>
                    <button disabled={loading} onClick={download} className="btn btn1">
                        {loading ? <><Icons.Download className='dlload' />&nbsp;Loading</> : 'Download'}
                    </button>
                </div>
            </div>
            <div className="footerbanner downloadbanner">
                <div className="buttoncontainer">
                    <button disabled={loading} className="btn btn1" onClick={onCancel}>
                        Return To Sample Page
                    </button>
                </div>
            </div>
        </Page>
    );
}

export default DownloadView;
