import React, {FC, useContext, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import SampleDetailView from '.';
import { ShareAndDownload, Page } from '../../Components';
import {couldNotShareResult} from "../../requests";
import {StateContext} from "../../state";

const SampleDetailViewCollection: FC = () => {
    const {sample_id} = useParams<any>();
    const history = useHistory();
    const [sample, setSample] = useState<any>();
    const [report, setReport] = useState<any>();
    const isServiceUser = sample && sample.collected_from === 'USER'
    const isShared = sample && !!sample.shared_at
    const [, setLoading] = useState<boolean>(false);
    const {dispatch} = useContext(StateContext);


    async function markAsCouldNotShared(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();

        try {
            setLoading(true);
            await couldNotShareResult(sample.sample_id, {});
            dispatchEvent(new CustomEvent('getSample', {detail: () => {
                    dispatch({type: "showAlert", payload: {
                            message: `${sample.sample_id} Sample Status Updated`,
                            status: 'Status: Couldn\'t Share',
                        }});
                }}))
        } catch (err: any) {
            dispatch({type: "showAlert", payload: {
                    error: true,
                    message: err.message,
                }});
        } finally {
            setLoading(false);
        }
    }


    return (
        <Page mainClass="sampleview">
            <SampleDetailView onSample={setSample} onReport={setReport}>
                { sample && sample.state!=="COULD_NOT_SHARE" &&<ShareAndDownload sample={sample} report={report} />}
                {(isServiceUser && !isShared && sample.state!=="COULD_NOT_SHARE" ) &&
                    <button
                    className="btn btn2"
                    onClick={markAsCouldNotShared}>
                        Couldn’t Share
                </button>
                }
                {sample && sample.state === "FILLED" && 
                    <>
                        <button className="btn btn2" onClick={() => history.push(`/samples/${sample_id}/delete`)}>
                            Request To Delete
                        </button>
                        {sample && !sample.analysis_site && sample.state === "FILLED" &&
                            <button className="btn btn2" onClick={() => history.push(`/collection-survey`, {sample: sample})}>
                                Edit Collection Data
                            </button>
                        }
                    </>
                }
            </SampleDetailView>
        </Page>
    )
}

export default SampleDetailViewCollection;
