import React, {FC} from 'react';
import { Route, Switch } from 'react-router-dom';

import { 
    ManageSamplesCollection,
    ResultShareDetailView,
    ShareDetailView
} from '..';
import { Page } from '../../Components';
import CollectionSurveyRoute from '../CollectionSurvey/route';
import PrepareTransportRoute from '../PrepareTransport/route';
import RequestDeleteSampleRoute from '../RequestDeleteSample/route';
import SampleDetailViewCollection from '../SampleDetailView/collection';
import DownloadView from '../DownloadView';

const CollectionRoutes: FC = () => {
    return (
        <Switch>
            <Route path="/prepare-samples-for-transport" exact>
                <PrepareTransportRoute />
            </Route>
            <Route path="/collection-survey" exact>
                <CollectionSurveyRoute />
            </Route>
            <Route path="/samples/:sample_id" exact>
                <SampleDetailViewCollection />
            </Route>
            <Route path="/samples/:sample_id/delete" exact>
                <RequestDeleteSampleRoute />
            </Route>
            <Route path="/samples/:sample_id/result" exact>
                <ResultShareDetailView />
            </Route>
            <Route path="/samples/:sample_id/share" exact>
                <ShareDetailView />
            </Route>
            <Route path="/samples/:sample_id/download" exact>
                <DownloadView />
            </Route>
            <Route path="/" exact>
                <Page>
                    <ManageSamplesCollection />
                </Page>
            </Route>
        </Switch> 
    );
}

export default CollectionRoutes;
