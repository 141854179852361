import React, {FC, useCallback, useContext, useEffect, useState} from 'react';
import { useHistory } from 'react-router';

import { Checkbox, Fullname } from '../../Components';
import { getUserFacingSampleType } from '../../helpers';
import Icons from '../../Icons';
import { prepareTransport } from '../../requests';
import { StateContext } from '../../state';

type IProps = {
    checkedSamples: any[];
    onDone: (checkedSamples: any[], fullname?: string) => void;
    onLoading: (status: boolean) => void;
    sample?: string;
}

const PrepareTransport: FC<IProps> = (props: IProps) => {
    const {dispatch} = useContext(StateContext);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [checkedSamples, setCheckedSamples] = useState<any[]>([]);
    const [fullname, setFullname] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [destSamples, setDestSamples] = useState<any>({});
    const [sites, setSites] = useState<any>({});
    const [why, setWhy] = useState<string>('');
    const history = useHistory();

    useEffect(() => {
        const sample_ids = props.checkedSamples.map((cs: any) => cs.sample_id);
        prepareTransport(true, sample_ids).then(res => {
            const getSite = (smpl_id: string) => {
                const site = res.data.find((site: any) => site.samples.includes(smpl_id));
                return site.destsite
            }

            const dest_sites: any = {}
            let dest_samples: any = {}
            props.checkedSamples.forEach((cs: any) => {
                const dest_site = getSite(cs.sample_id);
                dest_sites[dest_site.name] = dest_site.id;
                if (dest_samples[dest_site.name]) {
                    dest_samples[dest_site.name].push(cs);
                } else {
                    dest_samples[dest_site.name] = [cs];
                }
            })
            setSites(dest_sites);
            setDestSamples(dest_samples);
            setCheckedSamples(props.checkedSamples);
        }).catch((err: any) => {
            dispatch({type: "showAlert", payload: {
                message: err.message,
                error: true
            }});
            history.goBack();
        })

    }, [props.checkedSamples, dispatch, history])

    useEffect(() => {
        props.onLoading(loading);
    }, [loading, props])

    const submitHandler = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (checkedSamples.length === 0) {
            props.onDone([], fullname);
            return;
        }
        setLoading(true);
        Promise.all(
            Object.keys(destSamples).map((site: string) => {
                const samples: string[] = [];
                destSamples[site].forEach((cs: any) => {
                    if (checkedSamples.find(s => s.sample_id === cs.sample_id)) {
                        samples.push(cs.sample_id);
                    }
                })
                if (samples.length > 0) {
                    return prepareTransport(false, samples, fullname, sites[site], why);
                }
                return null;
            })
        ).then(() => {
            props.onDone(checkedSamples, fullname);
        }).catch((err: any) => {
            dispatch({type: "showAlert", payload: {
                error: true,
                message: err.message,
            }});
        }).finally(() => setLoading(false))
    }, [checkedSamples, fullname, destSamples, dispatch, props, sites, why])

    const IsDisabled =  loading || !fullname || (!props.sample && !confirm) || (props.sample !== undefined && !why );

    return (
        <form className="receiptsamples" onSubmit={submitHandler}>
            <div className="field">
                {!props.sample &&
                    <label className="label">
                        Check all samples that have been packaged for transport. Don't forget to write the name of the lab the samples are being transported to on the travel bag.
                    </label>
                }
                {Object.keys(destSamples).map((site: string, idx: number) => {
                    return (
                        <div className={`box ${site}`} key={idx}>
                            {props.sample &&
                                <div className="analysis">Analysis Site</div>
                            }
                            <div className="boxheader">
                                <Icons.Location/> {props.sample ? site : `${site} Samples`}
                            </div>
                            {destSamples[site].map((sample: any, index: number) => {
                                return (
                                    <div key={index} className="boxrow">
                                        {!props.sample &&
                                            <Checkbox
                                                checked={!!checkedSamples.find(cs => cs.sample_id === sample.sample_id)}
                                                onChange={(s: boolean) => {
                                                    const item = checkedSamples.find(cs => cs.sample_id === sample.sample_id);
                                                    if (item && !s) {
                                                        setCheckedSamples(os => os.filter(o => o.sample_id !== item.sample_id));
                                                    } else if (!item && s) {
                                                        setCheckedSamples(os => [...os, sample]);
                                                    }
                                                }} 
                                            />
                                        }
                                        <div className="boxsample" style={{marginRight: '4.75rem'}}>
                                            <span className="sampleidlabel">Sample ID:</span>
                                            <span className="sampleid">{sample.sample_id}</span>
                                        </div>
                                        <div className="boxsample">
                                            <span className="sampleidlabel">Sample type:</span>
                                            <span className="sampleid">{getUserFacingSampleType(sample.sample_type)}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
            <div className="field">
                <label className="label">
                    Full name of staff that prepared these samples for transport: <span className="required">*</span>
                </label>
                <Fullname onChange={(fullname: string) => setFullname(fullname)} />
            </div>
            {props.sample &&
                <div className="field">
                    <label className="label">Why are you sending this sample for further analysis? <span className="required">*</span></label>
                    <textarea
                        style={{width: '27.875rem'}}
                        value={why}
                        onChange={e => setWhy(e.target.value)}
                    />
                </div>
            }
            {!props.sample &&
                <div className="field">
                    <Checkbox
                        required={true}
                        checked={confirm} 
                        onChange={(s: boolean) => setConfirm(s)}
                        label="I confirm all samples are accounted for"
                    />
                </div>
            }
            <div className="field">
                <button disabled={IsDisabled} type="submit" className="btn btn1">Submit</button>
            </div>
        </form>
    );
}

export default PrepareTransport;
