import React, {FC, useState} from 'react';
import { useParams, useHistory } from 'react-router-dom';

import SampleDetailView from '.';
import { Page } from '../../Components';
import { TabItem } from '../../types';

const SampleDetailViewAnalysis: FC = () => {
    const {sample_id} = useParams<any>();
    const history = useHistory();
    const [sample, setSample] = useState<any>();
    const [report, setReport] = useState<any>();

    return (
        <Page mainClass="sampleview">
            <SampleDetailView onSample={setSample} onReport={setReport}>
                {sample && !["IN_TRANSPORT", "DISPOSED", "MISSING"].includes(sample.state) && 
                    <>
                        {(!report || (report && report.is_draft)) &&
                            <button className="btn btn2" onClick={() => history.push(`/prepare-for-transport`, {
                                checked_samples: [sample],
                                sample: sample_id,
                            })}>
                                Prepare For Transport
                            </button>
                        }
                        <button
                            className="btn btn2"
                            onClick={() => history.push('/dispose-samples', {
                                checked_samples: [sample],
                                sample: sample_id,
                            })}>
                                Dispose Sample
                        </button>
                        {(!report || (report && report.is_draft)) &&
                            <button className="btn btn1" onClick={() => history.push(`/report/${sample_id}`, {tab: TabItem.SamplesToReport})}>Report Analysis Data</button>
                        }
                    </>
                }
            </SampleDetailView>
        </Page>
    )
}

export default SampleDetailViewAnalysis;
