import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import Icons from '../../Icons';

import './style.css';

type IProps = {
    title: string;
    onClose: Function;
    children: any;
    ButtonNoTitle?: string;
    ButtonYes?: JSX.Element;
}

const Modal: FC<IProps> = (props: IProps) => {
    const modalRef = useRef<any>();
    const [hide, setHide] = useState<boolean>(false);

    const Close = useCallback(() => {
        setHide(true);
        setTimeout(() => {
            props.onClose();
        }, 300);
    }, [props])

    useEffect(() => {
        const handleClick = (event: any) => {
            if(modalRef && modalRef.current){
                const ref: any = modalRef.current;
                if (!ref.contains(event.target)) {
                    Close();
                }
            }
        }
        window.addEventListener("click", handleClick);

        const escapeHandler = (event: any) => {
            if(event.key === "Escape") {
                Close();
            }
        }
        window.addEventListener("keydown", escapeHandler);

        return () => {
            window.removeEventListener("click", handleClick);
            window.removeEventListener("keydown", escapeHandler);
        }
    }, [Close])

    return (
        <div className="modal-shadow">
            <div className={`modal ${hide ? 'hide' : 'show'}`} ref={modalRef}>
                <div className="modal-title">
                    <span>{props.title}</span>
                    <Icons.CloseBtn onClick={() => Close()} />
                </div>
                {props.children}
                <div className="modal-buttons">
                    {props.ButtonNoTitle &&
                        <button className="btn btn2" onClick={() => Close()} style={{marginRight: '0.5rem'}}>{props.ButtonNoTitle}</button>
                    }
                    {props.ButtonYes && props.ButtonYes}
                </div>
            </div>
        </div>
    );
}

export default Modal;
