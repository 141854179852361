import React, { FC, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { StateContext } from '../../state';
import { shareResult } from '../../requests';
import Icons from '../../Icons';
import './style.css';

type IProps = {
    sample: any
    report: any
}

const ShareAndDownload: FC<IProps> = (props: IProps) => {
    const {dispatch} = useContext(StateContext);
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const { sample, report } = props;
    const isServiceUser = sample && sample.collected_from === 'USER'
    const isShared = sample && !!sample.shared_at

    if (!report || !report.reported_at) return <></>

    if (isServiceUser) {
        return isShared ? <Link to={`/samples/${sample.sample_id}/download`} className="btn btn1"><Icons.Download className="dlbtn" /> Download Results</Link> : <button className="btn btn1" onClick={() => history.push(`/samples/${sample.sample_id}/share`)}>Download & Share Results</button>
    }

    async function markAsShared(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        try {
            setLoading(true);
            await shareResult(sample.sample_id, {});
            dispatchEvent(new CustomEvent('getSample', {detail: () => {
                dispatch({type: "showAlert", payload: {
                    message: `${sample.sample_id} Sample Status Updated`,
                    status: 'Status: Results Shared',
                }});
            }}))
        } catch (err: any) {
            dispatch({type: "showAlert", payload: {
                error: true,
                message: err.message,
            }});
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Link to={`/samples/${sample.sample_id}/download`} className="btn btn2"><Icons.DownloadBlack className="dlbtn" /> Download</Link>
            {!isShared &&
                <button disabled={loading} onClick={markAsShared} className="btn btn1 ml-1">Mark As Shared</button>
            }
        </>
    );
}

export default ShareAndDownload;
