import React, { FC, useReducer } from 'react';
import { BrowserRouter, Route } from "react-router-dom";

import { StateContext, reducer, initialState } from './state';
import { Notifications } from './Components';
import { CourierRoutes, Login } from './Pages';
import { AnalysisRoutes, CollectionRoutes } from './Pages/Routes';

import './style.css';
import { useToken } from './helpers';

const App: FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useToken();

  return (
      <StateContext.Provider value={{ state, dispatch }}>
        <BrowserRouter>
          <Route path="/routes" exact>
            <CourierRoutes />
          </Route>
          {state.user && 
            <>
              {state.user.performs_collection && <CollectionRoutes />}
              {state.user.performs_analysis && <AnalysisRoutes />}
            </>
          }
          {!state.user && 
            <Route path="/" exact>
              <Login />
            </Route>
          }
        </BrowserRouter>
        <Notifications />
      </StateContext.Provider>
  );
}

export default App;
