import { useContext, useEffect } from "react";

import { SampleStatuses, SampleType } from "./types";
import { StateContext } from "./state";
import { getPerms } from "./requests";
import Icons from "./Icons";

export const SampleStatusByLabel = (label: string) => {
  switch (label) {
    case "FILLED":
      return SampleStatuses.AwaitingTransport;
    case "IN_TRANSPORT":
      return SampleStatuses.InTransit;
    case "IN_ANALYSIS":
      return SampleStatuses.UndergoingAnalysis;
    case "READY_FOR_ANALYSIS":
      return SampleStatuses.UndergoingAnalysis;
    case "ANALYSIS_COMPLETE":
      return SampleStatuses.ResultsAvailable;
    case "ANALYSIS_FAILED":
      return SampleStatuses.FurtherAnalysis;
    case "DISPOSED":
      return SampleStatuses.Disposed;
    case "DELETION_REQUESTED":
      return SampleStatuses.DeletionRequested;
    case "MISSING":
      return SampleStatuses.Missing;
    case "ANALYSIS_IMPOSSIBLE":
      return SampleStatuses.NotAnalyzed;
    case "COULD_NOT_SHARE":
      return SampleStatuses.CouldNotShare;
  }
};

export const GetSampleStatus = (
  status: SampleStatuses,
  report_state: string,
  shared_at: any,
  further_analysis_from: any,
  not_analyzed: boolean | string = false
) => {
  const { performs_analysis } = getPerms();

  switch (status) {
    case SampleStatuses.CouldNotShare:
      return {
        Label: "Couldn’t Share",
        Icon: Icons.CouldNotShareStatus,
      };
    case SampleStatuses.AwaitingTransport:
      return {
        Label: "Awaiting Transport",
        Icon: Icons.AwaitingTransport,
      };
    case SampleStatuses.DeletionRequested:
      return {
        Label: "Deletion Requested",
        Icon: Icons.DeletionRequested,
      };
    case SampleStatuses.Disposed:
      if (!!not_analyzed) {
        return {
          Label: "Not Analyzed",
          Icon: Icons.NotAnalyzed,
        };
      }
      if (performs_analysis) {
        return {
          Label: "Disposed",
          Icon: Icons.Disposed,
        };
      }
      if (shared_at) {
        return {
          Label: "Results Shared",
          Icon: Icons.ResultsShared,
        };
      }
      if (report_state === "ANALYSIS_COMPLETE") {
        return {
          Label: "Results Reported",
          Icon: Icons.ResultsReported,
        };
      }
      return {
        Label: "Undergoing Analysis",
        Icon: Icons.UndergoingAnalysis,
      };
    case SampleStatuses.FurtherAnalysis:
      return {
        Label: "Further Analysis",
        Icon: Icons.FurtherAnalysis,
      };
    case SampleStatuses.InTransit:
      return {
        Label: "In Transit",
        Icon: Icons.InTransit,
      };
    case SampleStatuses.Missing:
      return {
        Label: "Missing",
        Icon: Icons.Missing,
      };
    case SampleStatuses.ResultsAvailable:
      return {
        Label: "Results Available",
        Icon: Icons.ResultsAvailable,
      };
    case SampleStatuses.ResultsReported:
      return {
        Label: "Results Reported",
        Icon: Icons.ResultsReported,
      };
    case SampleStatuses.ResultsShared:
      return {
        Label: "Results Shared",
        Icon: Icons.ResultsShared,
      };
    case SampleStatuses.UndergoingAnalysis:
      if (shared_at) {
        const { performs_collection } = getPerms();
        if (performs_collection) {
          return {
            Label: "Results Shared",
            Icon: Icons.ResultsShared,
          };
        }
      }
      switch (report_state) {
        case "ANALYSIS_COMPLETE":
          return {
            Label: "Results Reported",
            Icon: Icons.ResultsReported,
          };
        default:
          if (further_analysis_from && performs_analysis) {
            return {
              Label: "Further Analysis",
              Icon: Icons.FurtherAnalysis,
            };
          }
          return {
            Label: "Undergoing Analysis",
            Icon: Icons.UndergoingAnalysis,
          };
      }
  }

  return {
    Label: "",
    Icon: Icons.Location,
  };
};

export const getCollectedFrom = (from: string) => {
  switch (from) {
    case "USER":
      return "Service user";
    case "STAFF_BEHALF_USER":
      return "Staff on behalf of a service user";
    case "STAFF_BEHIND":
      return "Staff for a sample that was left behind";
    case "STAFF_AGENCY":
      return "Staff from another harm reduction agency";
    default:
      return "";
  }
};

export const getShareResult = (result: string) => {
  switch (result) {
    case "IN_PERSON":
      return "In person";
    case "PHONE":
      return "By phone";
    case "EMAIL":
      return "By email";
    case "INTERNALLY":
      return "Internally";
    default:
      return "";
  }
};

export const ucfirst = (string: string) =>
  string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

export const removeUnderline = (string: string) => {
  const str = string.split("_");
  const result = [];
  for (let i = 0; i < str.length; i++) {
    if (i === 0) {
      result.push(ucfirst(str[i]));
    } else {
      result.push(str[i].toLowerCase());
    }
  }
  return result.join(" ");
};

const actionDescriptors: Record<string, string> = {
  COLLECTED: "Collected",
  EDITED: "Edited",
  DELETION_REQUESTED: "Deletion requested",
  DELETED: "Deleted",
  DISPOSED: "Disposed",
  DESTROYED: "Destroyed",
  PREPARED: "Prepared For Transport",
  DELIVERED: "Delivered",
  ANALYZED: "Analyzed",
  ACCEPTED: "Accepted From Transport",
  REPORTED_MISSING: "Reported Missing",
  FOUND: "Found",
};
export const describeAction = (action: string): string => {
  return actionDescriptors[action] || removeUnderline(action);
};

export const useToken = () => {
  const { state, dispatch } = useContext(StateContext);

  useEffect(() => {
    if (!state.user) return;
    const exp = state.user.exp * 1000;
    const now = new Date().getTime();
    if (exp < now) {
      dispatch({ type: "logout" });
    }
  }, [state, dispatch]);
};

export const collectedFrom = [
  { id: "service_user", text: "Service user" },
  { id: "staff_behalf", text: "Staff from my site" },
  { id: "staff_from", text: "Staff from another site" },
];

export const getCollectedFromItem = (id: string) => {
  switch (id) {
    case "USER":
      return collectedFrom[0];
    case "STAFF_BEHALF_USER":
      return collectedFrom[1];
    case "STAFF_BEHIND":
      return collectedFrom[2];
    case "STAFF_AGENCY":
      return collectedFrom[3];
  }
};

export const getYesNoItem = (value: undefined | true | false) => {
  switch (value) {
    case true:
      return { id: 2, text: "Yes" };
    case false:
      return { id: 1, text: "No" };
    default:
      return undefined;
  }
};

export const getYesNoDontKnowItem = (value: undefined | string) => {
  switch (value) {
    case "YES":
      return { id: 1, text: "Yes" };
    case "NO":
      return { id: 2, text: "No" };
    case "DONT_KNOW":
      return { id: 3, text: "Don't know" };
    case "DONT_HAVE_ANY_OF_THIS_DRUG_LEFT":
      return { id: 4, text: "Don't have any of this drug left" };
    default:
      return undefined;
  }
};

export const getResultsProvision = (value: undefined | string) => {
  switch (value) {
    case "IN_PERSON":
      return { id: 1, text: "In person" };
    case "PHONE":
      return { id: 2, text: "By phone" };
    case "EMAIL":
      return { id: 3, text: "By email" };
    default:
      return undefined;
  }
};

export const displayFormula = (quantity: any, sample_weight: any) => {
  if (quantity === undefined || sample_weight === undefined) return "";
  const result = (+quantity / +sample_weight) * 10;
  return (
    <div className="dfrow">
      <span>{parseFloat(result.toFixed(2))}</span>{" "}
      <span className="mg">mg</span>
    </div>
  );
};

export const getUserFacingSampleType = (sampleType: SampleType) => {
  return {
    [SampleType.PARAPHERNALIA]: "Equipment",
    [SampleType.SUBSTANCE]: "Substance",
  }[sampleType];
};
